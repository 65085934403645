.multipleSelection {
  width: 300px;
  background-color: #bcc2c1;
}

.selectBox {
  position: relative;
  padding: 1px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 3px;
}

.selectBox select {
  width: 100%;
  font-weight: 400;
  padding: 5px;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 15px;
}
.overSelect:focus {
  box-shadow: none;
}
#checkBoxes {
  display: none;
  border: 1px #cacecd solid;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: rgb(0 0 0 / 35%) 0px 0px 15px;
  background-color: #ffff;
  padding: 10px;
  border: 1px solid #72737450;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

#checkBoxes label {
  display: block;
}

.select-field {
  width: 100%;
  height: 35px;
  outline: none;
  outline-offset: none;
  border: none;
  background-color: #fff;
}

.save-btn {
  background-color: #0f75b4;
  border: none;
  color: white;
  padding: 10px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  right: 30px;
}

.option-select {
  font-weight: 300;
  padding: 20px;
}
