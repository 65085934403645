main {
  height: calc(100vh - 71px);
  margin-top: 71px;
  overflow: auto;
}
.filter-dropdown {
  right: 18px;
  position: absolute;
  top: 42px;
  z-index: 1000;
  float: left;
  min-width: 13rem;
  padding: 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  /* background-clip: padding-box; */
  /* border-radius: 0.25rem; */
  padding: 5px 5px 0px 5px;
  border-radius: 8px;
  box-shadow: 0px 15px 27px 0px rgba(0, 0, 0, 0.2);
}

.filter-menu-head {
  position: relative;
  text-align: left;
  color: #747f8b;
  font-weight: 500;
  padding: 8px 8px 0px 8px;
}

.filter-menu-head a {
  text-decoration: none;
}

.growthcurve {
  border: 1px solid #000;
  padding: 15px;
  border-radius: 10px;
  background-color: #d2cfcf17;
  padding: 23px;
  margin-bottom: 10px;
}

.form__label {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.narrative-div {
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  max-height: 700px;
  overflow-y: auto;
}

.v-chart-container {
  width: 90%;
  padding: 20px;
  margin: auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 22px 35px -16px rgb(0 0 0 / 10%);
}

@media (max-width: 768px) {
  .v-chart {
    max-height: auto;
    min-height: auto;
  }
}
