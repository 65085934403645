.modal-header {
  /* background: #00a79d; */
  background: #f0f0f0a8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.164);
}
.modal-header .close {
  /* padding: 1rem 1rem; */
  margin: 0rem 0rem 0.1rem auto;
  background-color: transparent;
  border-radius: 20px;
  cursor: pointer;
}
.close {
  float: right;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  color: black;
  opacity: 0.7;
  border: none;
}
.close:hover {
  color: black;
  opacity: 1;
  text-decoration: none;
}

.modal-title {
  margin-bottom: 0;
  /* line-height: 1.5; */
  color: #000;
  margin: 0 10px;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  overflow: auto;
  max-height: 70vh;
  max-width: 100vw;
}
.modal-dialog {
  position: relative;
  width: auto;
  /* margin: 0.5rem; */
  pointer-events: none;
}
.modal-dialog {
  max-width: 500px;
  margin: 15rem auto;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 11px;
  outline: 0;
  box-shadow: rgba(50, 50, 50, 0.14) -2px 4px 6px 1px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-header h5 {
  color: white;
}

.modal-container {
  width: 100%;
}
