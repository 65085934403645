#chart {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 22px 35px -16px rgb(0 0 0 / 10%);
  max-width: 1000px;
  margin: 35px auto;
}

.v-chart {
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  /* display: flex; */
  align-items: center;
  /* background: #fff; */
  /* border: 1px solid #ddd; */
  /* box-shadow: 0 22px 35px -16px rgb(0 0 0 / 10%); */
  /* max-width: 80%; */
  /* height: 83vh; */
  min-height: 80vh;
  max-height: 83vh;
  /* margin: 0 auto; */
  border-radius: 0.5rem;
}
.graph-tooltip {
  background-color: yellow;
  border: white;
  line-height: normal;
}
.graph-tooltip > span {
  line-height: normal;
  text-align: center;
}

.button-control {
  float: right;
  background-color: #5850c8;
  padding: 10px;
  margin: 2px;
  color: white;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.button-control:hover {
  opacity: 0.8;
}
